import React from "react"
import { Spinner } from "react-bootstrap"
class EmailInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      isSubmitted: false,
      isSubmitting: false,
      submissionError: false,
    }
  }

  isValidEmail = email => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/ // Simple email regex
    return emailRegex.test(email)
  }
  handleInputChange = event => {
    this.setState({ email: event.target.value })
  }

  handleSubmit = async event => {
    event.preventDefault()
    const { email } = this.state
    this.setState({
      isSubmitting: true,
      isSubmitted: false,
      submissionError: false,
    })

    const formData = new FormData()
    formData.append("form-name", "wealthybites")
    formData.append("email", email)

    try {
      await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData).toString(),
      })
      this.setState({ isSubmitted: true, isSubmitting: false })
      this.setState({ email: "" })
    } catch (error) {
      this.setState({ submissionError: true, isSubmitting: false })
    }
  }

  render() {
    const { className } = this.props
    const { isSubmitted, submissionError, isSubmitting, email } = this.state
    const isSuccessfullySubmitted = isSubmitted && !submissionError
    return (
      <div
        id="content"
        className={`d-flex flex-column justify-content-center ${className ??
          ""}`}
      >
        <form
          className="text-medium mb-3"
          name="wealthybites"
          method="POST"
          onSubmit={this.handleSubmit}
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" value="wealthybites" />
          <input
            name="email"
            disabled={isSubmitting || isSuccessfullySubmitted}
            className="p-2 mb-3 pl-3 email-input-radious w-100 border-radius-32 gray-border text-regular"
            type="text"
            placeholder="Type your email here..."
            value={this.state.email}
            onChange={this.handleInputChange}
          />
          <button
            type="submit"
            disabled={
              !this.isValidEmail(email) ||
              isSubmitting ||
              isSuccessfullySubmitted
            }
            className="d-none d-lg-block cta-input-button btn button-medium text-medium btn-primary bg-dark"
          >
            {isSubmitting ? (
              <span className="p-2">
                <Spinner
                  animation="border"
                  variant="light"
                  className="text-white"
                />
              </span>
            ) : (
              <span>Sign me up!</span>
            )}
          </button>
          <button
            type="submit"
            disabled={
              !this.isValidEmail(email) ||
              isSubmitting ||
              isSuccessfullySubmitted
            }
            className="d-block d-lg-none cta-input-button btn email-cta-button text-regular btn-primary bg-dark"
          >
            <span>
              {isSubmitting ? (
                <Spinner animation="border" variant="primary" />
              ) : (
                <span>Sign me up!</span>
              )}
            </span>
          </button>
        </form>
        {isSuccessfullySubmitted && (
          <div className="text-center text-white text-big">
            Thank you for subscribing!
          </div>
        )}
        {submissionError && (
          <div className="text-center text-white text-medium">
            There was an error submitting the form. Please try again.
          </div>
        )}
      </div>
    )
  }
}

export default EmailInput
