import React from "react"
import * as Sentry from "@sentry/gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import EmailInput from "../components/email-input"

class LandingPageIndex extends React.Component {
  render() {
    const title = "Daily Investing News"
    const description =
      "Quick reads for smart investing: Daily market trends and tips and articles."

    return (
      <Layout activePage={"index"}>
        <SEO description={description} title={title} canonicalUrlPath={"/"} />
        <div className="container space-4">
          <div className="row mb-4">
            <h3 className="col-12 col-md-7 mb-6 mb-md-0">
              Start your morning with the day's top stories about the markets!
              💸
            </h3>
            <EmailInput className="col-12 col-md-5" />
          </div>
        </div>
        <div className="bg-color-main space-top-2 space-bottom-3 text-center">
          <div className="hero-image-container-dimensions">
            <img
              className="contain-image-to-parent-size hero-image-dimensions"
              src="/img/hero-asset.png"
              loading="lazy"
              alt={"wealthybites"}
            ></img>
          </div>
          <div className="container text-center pt-3 pb-4">
            <h3 className="text-white mb-5">
              Invest in yourself along with your money!
            </h3>
            <p className="text-white text-medium">
              Unlock your financial future with your daily Wealthybites! Get the
              latest market news and investing insights delivered straight to
              your inbox every morning. Stay informed, stay ahead, and let’s
              navigate the path to prosperity together! 🌟
            </p>
          </div>
        </div>
        <div className="white-background">
          <div className="container space-3">
            <div className="row mt-6">
              <div className="col-12 col-md-6 text-center d-flex flex-column align-items-center justify-content-center gap-3">
                <div className="pb-3">
                  <img
                    className="contain-image-to-parent-size wealthybites-category-image-size"
                    src="/img/daily-news.png"
                    loading="lazy"
                    alt={"News"}
                  />
                </div>
                <h3>Daily news</h3>
                <p>
                  Unlock the power of informed investing. Get your daily dose of
                  the top market and investing news straight to your inbox every
                  day!
                </p>
              </div>
              <div className="col-12 col-md-6 text-center d-flex flex-column align-items-center justify-content-center gap-3">
                <div className="pb-3">
                  <img
                    className="contain-image-to-parent-size wealthybites-category-image-size"
                    src="/img/analyst-insights.png"
                    loading="lazy"
                    alt={"Analyst insights"}
                  />
                </div>
                <h3>Analyst insights</h3>
                <p>
                  Empower your investment strategy with our daily analyst
                  insights! Elevate your market knowledge with exclusive expert
                  perspectives each day!
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-color-main space-top-3 space-bottom-4 text-center">
          <div className="container text-center pb-3 pt-5">
            <h3 className="text-white mb-5">
              The smart way to learn about investing and the markets
            </h3>
            <p className="text-white text-medium">
              Navigate through bespoke learning guides, and absorb actionable
              insights from expert articles. Your journey toward mastering
              investing and understanding the markets starts here. Turn
              knowledge into power, and power into prosperous investments!
            </p>
          </div>
          <img
            className="contain-image-to-parent-size"
            src="/img/guides-asset.png"
            loading="lazy"
            alt={"Leaning guides"}
          />
        </div>
      </Layout>
    )
  }
}

export default LandingPageIndex
